:root {
  --color-white: white;
  --color-dark: #161f35;
  --color-gray: #383d40;

  color: var(--color-dark);

  --header-height: 80px;
  --padding-inline: 2rem;
}

.slick-prev:before,
.slick-next:before {
  color:  var(--color-dark);
}

@media (max-width: 710px) {
    :root {
        --padding-inline: 1rem;
    }
}
  