.newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 28px 20px 0px 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  max-width: 400px;
  text-align: center;
  margin-bottom: 3rem;
  h1 {
    font-weight: 700;
  }
  h3 {
    font-weight: 500;
    max-width: 80%;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
  &__input {
    display: flex;
    flex-direction: row;
    gap: 0;
    button {
      position: absolute;

      transform: translate(240px, 14px);
      background: none repeat scroll 0 0 transparent;
      border: medium none;
      border-spacing: 0;

      font-size: 16px;
      line-height: 1.42rem;
      list-style: none outside none;
      margin: 0;
      padding: 0;
      text-align: left;
      text-decoration: none;
      text-indent: 0;
      cursor: pointer;
      font-weight: 700;
    }
    button:hover {
      opacity: 0.5;
    }
    input {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 16px;
      font-weight: 500;
      border-radius: 100px;
      line-height: 1.5;
      padding: 10px 20px;
      padding-right: 120px;
      transition: box-shadow 100ms ease-in, border 100ms ease-in,
        background-color 100ms ease-in;
      border: 2px solid #dee1e2;
      color: rgb(14, 14, 16);
      background: #dee1e2;
      display: block;
      height: 26px;
      min-width: 190px;
      max-width: 190px;

      &:hover {
        border-color: #ccc;
      }
      &:focus {
        border-color: var(--color-dark);
        background: #fff;
      }
      &::placeholder {
        font-weight: 500;
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 30px #f8f3f3 inset !important;
      -moz-box-shadow: 0 0 0 30px #f8f3f3 inset !important;
      -o-box-shadow: 0 0 0 30px #f8f3f3 inset !important;
      box-shadow: 0 0 0 30px #fbfafa inset !important;
      -webkit-text-fill-color: var(--color-dark) !important;
      -o-text-fill-color: var(--color-dark) !important;
      -moz-text-fill-color: var(--color-dark) !important;
    }
    input:focus-visible {
      outline-color: var(--color-dark);
    }
    textarea:focus,
    input:focus {
      outline: none;
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
      box-shadow: none;
    }
  }
}

@media (max-width: 408px) {
  .newsletter {
    h1{
        font-size: 1.9rem;
        
    }
    h3 {
 
        max-width: 90%;

      }
    &__input {

      input {
  
      }
    }
  }
}
