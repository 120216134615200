.cartElement{
    display: flex;
    justify-content: space-between;

    &__name{
        font-size: 2rem;
        margin-top: 0.5rem;
        font-weight: 400;

        font-style: normal;
    }
  
    &__price{
        font-size: 2rem;
        margin-top: 0.5rem;
        font-weight: 400;

        font-style: normal;
    }
    &__remove{
        font-size: 1.2rem;
        text-decoration: underline;
        text-underline-offset: 0.3rem;

        cursor: pointer;
    }
    &__remove:hover{
        color: rgb(147, 147, 147);
    }
}

@media (max-width: 768px) {
    .cartElement {
        &__name {
            font-size: 1.2rem;
        }
    
        &__price {
            font-size: 1.2rem;
        }
        &__remove {
            font-size: 1rem;
        }
    }
}