.product{
    
    width: 100vw;
    display: flex;

    justify-content: center;
    flex-wrap: wrap ;
    align-items: center;
    height: 100vh;
    gap: 5rem;
    row-gap: 0rem;
    
    &__info{
        max-width: 390px;
        &__talent{
            
            font-weight: 400;
            font-size: 1.2rem;
        }
        &__price{
            font-size: 1.4rem;
            margin-bottom: 2rem;
        }
        &__desc{
            margin-bottom: 2rem;
        }
        &__btn{
            
            font-size: 1.2rem;
            text-decoration: underline;
            text-underline-offset: 0.3rem;
            cursor: pointer;
        }
        &__btn:hover{
            color: rgb(147, 147, 147);
        }
    }
    &__img{
        width: 23rem;
        height: 81vh;
        object-fit: cover;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
        ul button{
            transform: scale(2);
        }
    }

    &__cartIcon{
        position: absolute;
        right: var(--padding-inline);

        top: 2rem;
        cursor: pointer;
        width: 35px;
        img{
            width: inherit;
        }
    }

    &__backIcon{
        position: absolute;
        left: var(--padding-inline);
        top: 2rem;
        cursor: pointer;
        width: 35px;
        transform: rotate(90deg);
        img{
            width: inherit;
        }
    }
}


@media (max-width: 710px) { 
    .product{
        margin-top: 10vh;
        margin-bottom: 20vh;
        height: fit-content;
        &__info{
            margin: 0 auto;
            margin-top: 2rem;
            width: 90vw;

        }
        &__img{
         
            width: 68vw;
            height: 90vw;
            object-fit: cover;
        }
    }
}

