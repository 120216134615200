.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__title {
    margin-top: 8vh;
    width: clamp(100px, 50%, 1200px);
  }
 
  &__services{
    div{
      margin: 1rem;
      margin-block: 1.8rem;
      h3{
        margin-block: 0.3rem;
      }
      ul{
        margin-inline: 1rem;
      }
    }
  }

  &__contacts{
  
      margin: 1rem;
    & > div{
      img{
        margin-right: 10px;
        filter: invert(9%) sepia(11%) saturate(7422%) hue-rotate(200deg) brightness(95%) contrast(92%); 

      }
      a{
        text-decoration: none;
        color: var(--color-dark);
        font-weight: 500;
        transform-origin: center left;
        transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
      a:hover{
        transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        transform: scale(1.05);
        transform-origin: center left;
        opacity: 0.8;
      }
      display: flex;
      flex-direction: row;
      margin-bottom: 0.4rem;
    }
      
    
  }

  &__nav {
    position: absolute;
    bottom: calc(8vh + 10px);
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    gap: 9rem;

    &__num {
      text-decoration: none;
      color: var(--color-dark);
      font-weight: 900;
      font-size: 9rem;
      transition: all 0.2s ease-out;
      opacity: 0.2;

      &__visible {
        opacity: 1;
      }
    }

    &__numImg {
      text-decoration: none;
      height: 120px;
      transform: translateY(50px);
      transition: all 0.2s ease-out;
      opacity: 0;

      &__visible {
        transform: translateY(0px);
        opacity: 1;
      }
    }

    &__item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      color: var(--color-dark);
      font-weight: 600;
      font-size: 2rem;
      transform-origin: center bottom;
      transition: all 0.2s ease-out;
      p {
        margin-bottom: -1.4rem;
      }
    }
    &__active {
      text-decoration: underline;
    }

    &__item:hover {
      // color: rgb(96, 96, 96);
      transform: scale(1.1);
      color: rgb(0, 0, 0);
      transition: all 0.2s ease-out;
    }
  }

  &__section {
    margin-top: 86vh;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
    gap: 3rem;

    &__text {
      max-width: 860px;
      color: var(--color-dark);
      font-size: 1.2rem;
      font-weight: 500;
      padding-left: 2rem;
      padding-right: 2rem;
      // margin-right: 20rem;
    }

    &__img {
      object-fit: cover;
      object-position: center;
      width: 40vw;
      height: 300px;
      margin-left: 30vw;
    }

    &__1 {
      max-width: 1200px;
      display: flex;
      flex-direction: column;

      gap: 2rem;
    }

    &__2 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 710px) {
  .about {
    &__title {
      margin-top: 4vh;
      cursor: pointer;
      margin-bottom: 8vh;
      width: clamp(100px, 60%, 1200px);
      margin-right: auto;
      margin-left: var(--padding-inline);
    }

    &__section {
      align-items: normal;
      &__text {
        width: 87%;
        font-size: 1.1rem;
        margin-left: 0;
        margin-right: 0;
        padding-right: 0;
        padding-left: 1rem;
      }
      &__img {
        width: 90vw;
        height: 300px;
        margin-left: 0;
      }
    }

    &__nav {
      position: absolute;
      flex-direction: column;
      bottom: calc(8vh + 20px);
      align-items: center;

      width: 100%;
      gap: 1.3rem;
      justify-content: space-between;
      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.2rem;
      }
      &__numImg {
        text-decoration: none;
        height: 100px;

        transform: translateY(0px);
        opacity: 1;
      }
      
    }
  }
}
