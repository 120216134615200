.title {
    position: absolute;
    left: var(--padding-inline);
    
  margin-top: 4vh;
  cursor: pointer;
  margin-bottom: 8vh;
  width: clamp(100px, 20%, 1200px);
  margin-right: auto;
//   margin-left: var(--padding-inline);
}
@media screen and (max-width: 768px) {
    .title {
        width: clamp(100px, 60%, 1200px);
    }
}