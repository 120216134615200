.product{
    
    cursor: pointer;
    &__img{
        height: 300px;
        width: 190px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
            height: 300px;
            width: 190px;
           
            img{
                
                object-fit: cover;
            }
            object-fit: cover;
            overflow: hidden;
            -webkit-transition: .2s ease-in-out;
            transition: .2s ease-in-out;
        }
        height: 300px;
        width: 300px;
        
        overflow: hidden;
    }
    h2{
        text-align: center;
        max-width: 300px;
        color: var(--color-dark);
    }
    p{
        margin-top: 0.5rem;
        text-align: center;
        font-size: 1.1rem;
        text-decoration: underline;
        text-underline-offset: 0.3rem;
        cursor: pointer;
    }
    p:hover{
        color: rgb(147, 147, 147);
    }

    &__img:hover{
        img{
            -webkit-transform: scale(1.08);
            transform: scale(1.08);
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
        }
       
    }
}

.navlink{
    text-decoration: none;
    color: black;
    
}