.button{
    position: absolute;
    left: var(--padding-inline);
    top: 2rem;
    cursor: pointer;
    width: 35px;
    transform: rotate(90deg);
    img {
      cursor: pointer;
      width: inherit;
    }
}