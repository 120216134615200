.dropdown{
    width: 500px;
    margin-bottom: 1rem;
    height: fit-content;
   
    box-sizing: border-box;

    &__title{
        cursor: pointer ;
        display: flex;
        flex-direction: row;
        width: 100%;
        h1{
            font-size: 1.2rem;
        }
      
        border-bottom: 2px solid var(--color-dark);
    }
    &__minus{
        margin-left: auto;
        width: 15px;
        transform: translateX(17px);
        transition: all .4s cubic-bezier(0.19, 1, 0.22, 1);

        &__open{
            
      
            opacity: 1;
        }
        &__close{
        
            opacity: 0;
          
        }
    }
    &__plus{
        transform-origin: center center;
        width: 20px;
       
        transition: all .4s cubic-bezier(0.19, 1, 0.22, 1);
        &__open{
            
            transform: rotate(0deg) ;
            opacity: 0;
        }
        &__close{
            transform: rotate(90deg);
            
          
        }
    }
    &__content{
        //transform-origin: center top;
        transition: all linear .1s;
        //transition: 2s  cubic-bezier(0.075, 0.82, 0.165, 1);

        &__open {
            max-height: none;
            transform: translateY(0);
            opacity: 1;
            pointer-events: all;
            
          }
      
          &__close {
            pointer-events: none;
         
            max-height: 0;
            transform: translateY(-10px);
            opacity: 0;
          }
    
    }
}


@media (max-width: 768px) {
    .dropdown {
      
        width: 90%;
     
        
        &__title {
            font-size: 1.4rem;
          
        }
        
        &__content {
    
            &__open {
                
 
            }
            
            &__close {

            }
        }
    }
}