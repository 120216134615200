.shop{
    display: flex;
    align-items: center;
    flex-direction: column;
    &__title{
        margin-top: 4vh;
        margin-bottom: 8vh;
        width: clamp(100px, 25%, 1200px);
    }
    &__cartIcon{
        position: absolute;
        right: var(--padding-inline);
        top: 2rem;
        cursor: pointer;
        width: 35px;
        img{
            width: inherit;
        }
    }
    &__cartIcon:hover{
        
            -webkit-transform: scale(1.08);
            transform: scale(1.08);
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
        

    }
    &__list{
        

        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 90%;
        max-width: 1200px;
        margin: 0 auto;
        gap: 2rem;
        row-gap: 4rem;
        margin-bottom: 25vh;
        margin-top: 8rem;

    }
    &__product{
        cursor: pointer;
        &__img{
          
            img{
                height: 300px;
                width: 300px;
                overflow: hidden;
                -webkit-transition: .2s ease-in-out;
                transition: .2s ease-in-out;
            }
            height: 300px;
            width: 300px;
            
            overflow: hidden;
           
            
        }

        &__img:hover{
            img{
                -webkit-transform: scale(1.08);
                transform: scale(1.08);
                -webkit-transition: .3s ease-in-out;
                transition: .3s ease-in-out;
            }
           
        }
    }

    
}


@media (max-width: 710px) { 
    .shop{
        &__title{
            margin-top: 4vh;
            margin-bottom: 8vh;
            width: clamp(100px, 60%, 1200px);
            margin-right: auto;
            margin-left: 1rem;
        }
        &__list{
            justify-content: center;
        }
        &__product{
            &__img{
          
                img{
                    height: 90vw;
                    width: 90vw;
       
                }
                height:90vw;
                width:90vw;

               
                
            }
    
            &__img:hover{
                img{
                    -webkit-transform: scale(1.08);
                    transform: scale(1.08);
                    -webkit-transition: .3s ease-in-out;
                    transition: .3s ease-in-out;
                    z-index: -1;
                }
               
            }
        }
    }
 }