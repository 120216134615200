.footer{
   
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 2rem;
    margin-block: 2rem;
    gap: 1rem;
    


    &__social{
        filter: invert(9%) sepia(11%) saturate(7422%) hue-rotate(200deg) brightness(95%) contrast(92%); 
        cursor: pointer;
        width: 44px;
        transition: all 0.1s;
    }
    &__social:hover{
        transform: scale(1.16);
        transition: all 0.3s;
    }
    &__social:active{
        transform: scale(1.14);

    }
}