
$cardElementTall: 15vh;
$marginSide: 35px;
.cart{
    position: relative;
    display: flex;
    z-index: 999;
   
    &__topBar{
        margin-left: $marginSide;
        margin-right: $marginSide;
        
        
        height: $cardElementTall;
        
        display: flex;
        justify-content: space-between;
        align-items: center;
 

        &__title{
            h2{
                font-size: 2rem;
       
                font-weight: 500;
            }
            
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
        }

        &__ex{
            width: 38px;
            cursor: pointer;
        }

        &__ex:hover{
            color: rgba(0, 0, 0, 0.489);
        }
    }

    &__products{

        padding-left: $marginSide;
        padding-right: $marginSide;
        border-top: 0.1rem solid;
        height: calc(100vh - $cardElementTall * 3 - 4rem);
        overflow-y: scroll;
      

    
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    &__products::-webkit-scrollbar {
        display: none;
    }
      

    &__total{
        padding-left: $marginSide;
        padding-right: $marginSide;

        border-top: 0.1rem solid;
        border-bottom: 0.1rem solid;
        height: $cardElementTall;
        
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2{
            font-size: 2rem;
   
            font-weight: 400;
        }
    }

    &__checkout{
       cursor: pointer;
       background-color: white;
        height: $cardElementTall;
        width: inherit;
        transition: 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
 
        display: flex;
        justify-content: center;
        align-items: center;

        h2{
            font-size: 2rem;

            font-weight: 400;
        }
       
    }

    &__checkout:hover{
        background-color: white;

        height: $cardElementTall;
        width: inherit;
        transition: 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);

    }
}   

.tabOpen{
    background-color: white;
    position: fixed;
    right: 0;
    height: 100vh;
    transform-origin: center right;
    width: 35rem;
   
    transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    pointer-events: all;

}
.tabClose{
    background-color: white;
    position: fixed;
    right: 0;
    height: 100vh;
    transform-origin: center right;
    transform: translate(35rem);
    width: 35rem;
    transition:  0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

   

}

.overlayOpen{
    background-color: rgba(33, 14, 0, 0.33);
    position: fixed;
    left: 0;
    height: 100vh;
    transform-origin: center right;
    width: 100vw;
    pointer-events: all;
    transition:  1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.overlayClosed{

    position: fixed;
    left: 0;
    height: 100vh;
    transform-origin: center right;
    width: 100vw;
    pointer-events: none;
    transition:  1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.no{
    text-decoration: none;
}

@media (max-width: 768px) {
    .tabClose{
        transform: translate(180vw);
    }

.tabOpen{
    
    width: 100vw;

    

}
.tabClose{
    transform: translate(100vw);
    width: 100vw;
}


}
