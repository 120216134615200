.nav{
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    flex-direction: row;
    list-style: none;
    gap: 2rem;
    z-index: 5;
   

    &__item{
        text-decoration: none;
        color: var(--color-dark);
        font-weight: 600;
        
        font-size: 1.4rem;
       
    }
    &__active{
        text-decoration: underline;
        
    }
   
    &__item:hover{
        // color: rgb(96, 96, 96);
        color: rgb(0, 25, 115);
        transform: scale(1.1);
        transition: all 0.2s ease-out;
    }
    
    
    
}

@media (max-width: 710px) { 
    .nav{
        border-top: 2px solid var(--color-dark);
        background-color: white;
        padding-block: 3vh ;
        align-items: center;
        left: 0rem;
        bottom: 0rem;
        width: 100vw;
        //width: 90vw;
        gap: 0rem;
        justify-content: space-between;
        padding-inline: 1.1rem;
        box-sizing: border-box;
     
        &__item{
            font-size: 1.2rem;
            margin-inline: 0rem;
        }
    }
 }
