.article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-wrap: wrap;
  margin-bottom: 6rem;
  width: 100%;

  &__title {
    margin-top: 6rem;
    margin-inline: 13rem;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
  }
  &__subtitle {
    margin-inline: 13rem;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    color: var(--color-gray);
    text-align: center;
  }
  &__img {
    margin-inline: 8rem;
    max-height: 450px;
    object-fit: cover;
    width: 29%;
    align-self: center;
    margin-block: 2.5rem;

    img {

      max-height: 450px;
      object-fit: cover;
      width: 100%;

     

    }
  }
  &__imgDesc {
    width: fit-content;

    font-size: 14px;
    font-weight: 600;
    color: rgb(161, 161, 161);
  
  }

  &__bigImg {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    max-width: 700px;
    margin-inline: auto;
    width: 100%;
    img {

      max-width: 700px;
      margin-inline: auto;
      width: 100%;
    }
  }
  &__imgDescBig {
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
    color: rgb(161, 161, 161);
  }

  &__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin-inline: 9rem;
    max-width: 1000px;

    &__question {
      font-size: 1.5rem;
      font-weight: 800;
      margin-bottom: 1rem;
    }
    &__answer {
      color: var(--color-gray);
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
  }
  &__backIcon {
    position: absolute;
    left: var(--padding-inline);
    top: 2rem;
    cursor: pointer;
    width: 35px;
    transform: rotate(90deg);
    img {
      width: inherit;
    }
  }
}

@media (max-width: 768px) {
  .article {
    &__title {
      margin-inline: 2rem;
      font-size: 1.6rem;
    }
    &__subtitle {
      margin-inline: 2rem;
      font-size: 1.1rem;
    }
    &__img {
      margin-inline: 2rem;
      width: 26vh;
    }

    &__section {
      margin-inline: 2rem;
      margin-bottom: 3rem;
      &__answer {
        font-size: 1.1rem;
      }
    }
  }
}
